@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .inputStyle {
    @apply max-w-xs h-9 appearance-none font-medium w-full rounded-md text-secondary-100 border border-inputB bg-inputBg px-3 text-sm leading-9 placeholder:text-[#656565] outline-0 focus:border-primary-brand focus:bg-white disabled:bg-[#DADADA] invalid:required:border-red;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
body {
  font-size: 16px;
  color: #474f64;
  font-weight: 400;
}

.canvas-container canvas {
  width: 100% !important;
  height: 100% !important;
  max-width: 500px;
}

select {
  background: url(./components/atoms/images/select-arrow.svg) no-repeat center
    right 12px;
  padding-right: 26px !important;
}
img {
  max-width: 440px;
}
.sidebar-link.active {
  background: rgba(216, 239, 222, 1);
  color: #3eae5b;
  border-radius: 4px 0 0 4px;
}
[type="number"] {
  -moz-appearance: textfield;
}
