*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #eee;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Roboto, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #bdbdbd;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

* {
  scrollbar-color: initial;
  scrollbar-width: initial;
}

.inputStyle {
  height: 2.25rem;
  width: 100%;
  max-width: 20rem;
  appearance: none;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(218 218 218 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(234 234 234 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(25 35 61 / var(--tw-text-opacity));
  border-radius: .375rem;
  outline-width: 0;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 2.25rem;
}

.inputStyle::placeholder {
  --tw-text-opacity: 1;
  color: rgb(101 101 101 / var(--tw-text-opacity));
}

.inputStyle:required:invalid {
  --tw-border-opacity: 1;
  border-color: rgb(235 87 87 / var(--tw-border-opacity));
}

.inputStyle:focus {
  --tw-border-opacity: 1;
  border-color: rgb(62 174 91 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.inputStyle:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(218 218 218 / var(--tw-bg-opacity));
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2196f380;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1100px) {
  .container {
    max-width: 1100px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.\!fixed {
  position: fixed !important;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.\!left-0 {
  left: 0 !important;
}

.\!top-0 {
  top: 0 !important;
}

.-left-1 {
  left: -.25rem;
}

.-top-1 {
  top: -.25rem;
}

.-top-1\.5 {
  top: -.375rem;
}

.-top-2 {
  top: -.5rem;
}

.-top-2\.5 {
  top: -.625rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.left-0 {
  left: 0;
}

.left-1 {
  left: .25rem;
}

.left-1\/2 {
  left: 50%;
}

.left-1\/3 {
  left: 33.3333%;
}

.left-2\/4 {
  left: 50%;
}

.left-3 {
  left: .75rem;
}

.left-4 {
  left: 1rem;
}

.left-auto {
  left: auto;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: .25rem;
}

.right-10 {
  right: 2.5rem;
}

.right-2 {
  right: .5rem;
}

.right-3 {
  right: .75rem;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-1\/2, .top-2\/4 {
  top: 50%;
}

.top-3 {
  top: .75rem;
}

.top-4 {
  top: 1rem;
}

.top-8 {
  top: 2rem;
}

.top-\[14px\] {
  top: 14px;
}

.top-auto {
  top: auto;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[0\] {
  z-index: 0;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[999\] {
  z-index: 999;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-last {
  order: 9999;
}

.order-none {
  order: 0;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.m-0 {
  margin: 0;
}

.m-4 {
  margin: 1rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-2\.5 {
  margin-left: .625rem;
  margin-right: .625rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-\[20px\] {
  margin-top: 20px;
  margin-bottom: 20px;
}

.-mb-2 {
  margin-bottom: -.5rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-\[18px\] {
  margin-bottom: 18px;
}

.mb-\[30px\] {
  margin-bottom: 30px;
}

.mb-\[53px\] {
  margin-bottom: 53px;
}

.mb-\[93px\] {
  margin-bottom: 93px;
}

.ml-0 {
  margin-left: 0;
}

.ml-0\.5 {
  margin-left: .125rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-20 {
  margin-left: 5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-\[10px\] {
  margin-left: 10px;
}

.ml-\[4\] {
  margin-left: 4px;
}

.ml-\[9\.75px\] {
  margin-left: 9.75px;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-20 {
  margin-right: 5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-\[13\.8px\] {
  margin-right: 13.8px;
}

.mr-auto {
  margin-right: auto;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-3\.5 {
  margin-top: .875rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[0\.5px\] {
  margin-top: .5px;
}

.mt-\[18px\] {
  margin-top: 18px;
}

.mt-\[30px\] {
  margin-top: 30px;
}

.mt-\[46px\] {
  margin-top: 46px;
}

.mt-\[50vh\] {
  margin-top: 50vh;
}

.mt-px {
  margin-top: 1px;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.\!h-full {
  height: 100% !important;
}

.h-0 {
  height: 0;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[10px\] {
  height: 10px;
}

.h-\[110px\] {
  height: 110px;
}

.h-\[165px\] {
  height: 165px;
}

.h-\[285px\] {
  height: 285px;
}

.h-\[294px\] {
  height: 294px;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[37px\] {
  height: 37px;
}

.h-\[396px\] {
  height: 396px;
}

.h-\[542px\] {
  height: 542px;
}

.h-\[58px\] {
  height: 58px;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[74px\] {
  height: 74px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-max {
  height: max-content;
}

.h-screen {
  height: 100vh;
}

.max-h-3 {
  max-height: .75rem;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-\[100vh\] {
  max-height: 100vh;
}

.max-h-\[210px\] {
  max-height: 210px;
}

.max-h-\[32px\] {
  max-height: 32px;
}

.max-h-\[40px\] {
  max-height: 40px;
}

.max-h-\[48px\] {
  max-height: 48px;
}

.max-h-\[500px\] {
  max-height: 500px;
}

.max-h-\[90vh\] {
  max-height: 90vh;
}

.min-h-0 {
  min-height: 0;
}

.min-h-\[100px\] {
  min-height: 100px;
}

.min-h-\[100vh\] {
  min-height: 100vh;
}

.min-h-\[210px\] {
  min-height: 210px;
}

.min-h-\[250px\] {
  min-height: 250px;
}

.min-h-\[48px\] {
  min-height: 48px;
}

.min-h-\[542px\] {
  min-height: 542px;
}

.min-h-\[696px\] {
  min-height: 696px;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.\!w-full {
  width: 100% !important;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-2\/5 {
  width: 40%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-3\/4 {
  width: 75%;
}

.w-3\/5 {
  width: 60%;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-40 {
  width: 10rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-9 {
  width: 2.25rem;
}

.w-\[0\] {
  width: 0;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[103px\] {
  width: 103px;
}

.w-\[110px\] {
  width: 110px;
}

.w-\[1200px\] {
  width: 1200px;
}

.w-\[122px\] {
  width: 122px;
}

.w-\[143px\] {
  width: 143px;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[15px\] {
  width: 15px;
}

.w-\[190px\] {
  width: 190px;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[260px\] {
  width: 260px;
}

.w-\[282px\] {
  width: 282px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[364px\] {
  width: 364px;
}

.w-\[485px\] {
  width: 485px;
}

.w-\[532px\] {
  width: 532px;
}

.w-\[58px\] {
  width: 58px;
}

.w-\[74px\] {
  width: 74px;
}

.w-\[75\%\] {
  width: 75%;
}

.w-\[82px\] {
  width: 82px;
}

.w-\[calc\(100\%-160px\)\] {
  width: calc(100% - 160px);
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-screen {
  width: 100vw;
}

.min-w-\[100vw\] {
  min-width: 100vw;
}

.min-w-\[160px\] {
  min-width: 160px;
}

.min-w-\[175px\] {
  min-width: 175px;
}

.min-w-\[180px\] {
  min-width: 180px;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-\[232px\] {
  min-width: 232px;
}

.min-w-\[25\%\] {
  min-width: 25%;
}

.min-w-\[260px\] {
  min-width: 260px;
}

.min-w-\[33\.333333\%\] {
  min-width: 33.3333%;
}

.min-w-\[337px\] {
  min-width: 337px;
}

.min-w-\[34px\] {
  min-width: 34px;
}

.min-w-\[40\%\] {
  min-width: 40%;
}

.min-w-\[48px\] {
  min-width: 48px;
}

.min-w-\[57px\] {
  min-width: 57px;
}

.min-w-\[60\%\] {
  min-width: 60%;
}

.min-w-\[75\%\] {
  min-width: 75%;
}

.min-w-full {
  min-width: 100%;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-\[100vw\] {
  max-width: 100vw;
}

.max-w-\[1350px\] {
  max-width: 1350px;
}

.max-w-\[20px\] {
  max-width: 20px;
}

.max-w-\[25\%\] {
  max-width: 25%;
}

.max-w-\[320\] {
  max-width: 320px;
}

.max-w-\[323px\] {
  max-width: 323px;
}

.max-w-\[32px\] {
  max-width: 32px;
}

.max-w-\[33\.333333\%\] {
  max-width: 33.3333%;
}

.max-w-\[352px\] {
  max-width: 352px;
}

.max-w-\[40\%\] {
  max-width: 40%;
}

.max-w-\[400px\] {
  max-width: 400px;
}

.max-w-\[40px\] {
  max-width: 40px;
}

.max-w-\[48px\] {
  max-width: 48px;
}

.max-w-\[490\] {
  max-width: 490px;
}

.max-w-\[502px\] {
  max-width: 502px;
}

.max-w-\[60\%\] {
  max-width: 60%;
}

.max-w-\[600\], .max-w-\[600px\] {
  max-width: 600px;
}

.max-w-\[70px\] {
  max-width: 70px;
}

.max-w-\[75\%\] {
  max-width: 75%;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-screen-2xl {
  max-width: 1320px;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.basis-full {
  flex-basis: 100%;
}

.table-auto {
  table-layout: auto;
}

.table-fixed {
  table-layout: fixed;
}

.border-collapse {
  border-collapse: collapse;
}

.-translate-x-1\/2, .-translate-x-2\/4 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/4 {
  --tw-translate-y: -25%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-2\/4 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-0 {
  --tw-scale-x: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-100 {
  --tw-scale-x: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes wave {
  0% {
    transform: translateY(0);
  }

  60% {
    transform: translateY(0);
  }

  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
}

.animate-wave {
  animation: 1.3s linear infinite wave;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  user-select: none;
}

.\!resize-none {
  resize: none !important;
}

.resize-none {
  resize: none;
}

.resize-y {
  resize: vertical;
}

.\!resize {
  resize: both !important;
}

.resize {
  resize: both;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  appearance: none;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-items-center {
  place-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-\[26px\] {
  gap: 26px;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-y-\[24px\] {
  row-gap: 24px;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.\!overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.\!rounded-full {
  border-radius: 9999px !important;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded-\[12px\] {
  border-radius: 12px;
}

.rounded-\[15px\] {
  border-radius: 15px;
}

.rounded-\[1px\] {
  border-radius: 1px;
}

.rounded-\[2px\] {
  border-radius: 2px;
}

.rounded-\[4px\] {
  border-radius: 4px;
}

.rounded-\[5px\] {
  border-radius: 5px;
}

.rounded-\[6px\] {
  border-radius: 6px;
}

.rounded-\[7px\] {
  border-radius: 7px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-bl {
  border-bottom-left-radius: .25rem;
}

.rounded-tl {
  border-top-left-radius: .25rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-b, .border-b-\[1px\] {
  border-bottom-width: 1px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-\[\#3EAE5B\], .border-\[\#3eae5b\] {
  --tw-border-opacity: 1;
  border-color: rgb(62 174 91 / var(--tw-border-opacity));
}

.border-\[\#D6D4D0\] {
  --tw-border-opacity: 1;
  border-color: rgb(214 212 208 / var(--tw-border-opacity));
}

.border-\[\#EB5757\] {
  --tw-border-opacity: 1;
  border-color: rgb(235 87 87 / var(--tw-border-opacity));
}

.border-\[\#EBE8E4\] {
  --tw-border-opacity: 1;
  border-color: rgb(235 232 228 / var(--tw-border-opacity));
}

.border-\[\#F3BC42\] {
  --tw-border-opacity: 1;
  border-color: rgb(243 188 66 / var(--tw-border-opacity));
}

.border-\[\#FF5C5C\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 92 92 / var(--tw-border-opacity));
}

.border-\[\#c8cad0\] {
  --tw-border-opacity: 1;
  border-color: rgb(200 202 208 / var(--tw-border-opacity));
}

.border-\[\#dadada\] {
  --tw-border-opacity: 1;
  border-color: rgb(218 218 218 / var(--tw-border-opacity));
}

.border-amber-500 {
  --tw-border-opacity: 1;
  border-color: rgb(255 193 7 / var(--tw-border-opacity));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(33 150 243 / var(--tw-border-opacity));
}

.border-blue-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(207 216 220 / var(--tw-border-opacity));
}

.border-blue-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(176 190 197 / var(--tw-border-opacity));
}

.border-blue-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgb(236 239 241 / var(--tw-border-opacity));
}

.border-blue-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(96 125 139 / var(--tw-border-opacity));
}

.border-brown-500 {
  --tw-border-opacity: 1;
  border-color: rgb(121 85 72 / var(--tw-border-opacity));
}

.border-cyan-500 {
  --tw-border-opacity: 1;
  border-color: rgb(0 188 212 / var(--tw-border-opacity));
}

.border-deep-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgb(255 87 34 / var(--tw-border-opacity));
}

.border-deep-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(103 58 183 / var(--tw-border-opacity));
}

.border-extraLightBrown {
  --tw-border-opacity: 1;
  border-color: rgb(214 212 208 / var(--tw-border-opacity));
}

.border-fontSton {
  --tw-border-opacity: 1;
  border-color: rgb(154 154 154 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(158 158 158 / var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(76 175 80 / var(--tw-border-opacity));
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(63 81 181 / var(--tw-border-opacity));
}

.border-light-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(3 169 244 / var(--tw-border-opacity));
}

.border-light-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(139 195 74 / var(--tw-border-opacity));
}

.border-lightBorder {
  --tw-border-opacity: 1;
  border-color: rgb(218 220 224 / var(--tw-border-opacity));
}

.border-lime-500 {
  --tw-border-opacity: 1;
  border-color: rgb(205 220 57 / var(--tw-border-opacity));
}

.border-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgb(255 152 0 / var(--tw-border-opacity));
}

.border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgb(233 30 99 / var(--tw-border-opacity));
}

.border-primary-64 {
  --tw-border-opacity: 1;
  border-color: rgb(101 190 124 / var(--tw-border-opacity));
}

.border-primary-brand {
  --tw-border-opacity: 1;
  border-color: rgb(62 174 91 / var(--tw-border-opacity));
}

.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(156 39 176 / var(--tw-border-opacity));
}

.border-red {
  --tw-border-opacity: 1;
  border-color: rgb(235 87 87 / var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(244 67 54 / var(--tw-border-opacity));
}

.border-secondary-16 {
  --tw-border-opacity: 1;
  border-color: rgb(200 202 208 / var(--tw-border-opacity));
}

.border-secondary-2 {
  --tw-border-opacity: 1;
  border-color: rgb(250 251 251 / var(--tw-border-opacity));
}

.border-teal-500 {
  --tw-border-opacity: 1;
  border-color: rgb(0 150 136 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-white\/80 {
  border-color: #fffc;
}

.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(255 235 59 / var(--tw-border-opacity));
}

.\!border-t-transparent {
  border-top-color: #0000 !important;
}

.border-b-\[\#a3a7b1\] {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(163 167 177 / var(--tw-border-opacity));
}

.border-b-\[\#d6d4d0\] {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(214 212 208 / var(--tw-border-opacity));
}

.border-b-blue-gray-100 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(207 216 220 / var(--tw-border-opacity));
}

.border-l-transparent {
  border-left-color: #0000;
}

.border-r-transparent {
  border-right-color: #0000;
}

.border-t-blue-gray-100 {
  --tw-border-opacity: 1;
  border-top-color: rgb(207 216 220 / var(--tw-border-opacity));
}

.border-t-transparent {
  border-top-color: #0000;
}

.\!bg-\[\#000000b2\] {
  background-color: #000000b2 !important;
}

.bg-\[\#3eae5b\] {
  --tw-bg-opacity: 1;
  background-color: rgb(62 174 91 / var(--tw-bg-opacity));
}

.bg-\[\#D6D4D0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(214 212 208 / var(--tw-bg-opacity));
}

.bg-\[\#F3BC4233\] {
  background-color: #f3bc4233;
}

.bg-\[\#F7F5F0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(247 245 240 / var(--tw-bg-opacity));
}

.bg-\[\#F9F9F9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}

.bg-\[\#FCF1ED\] {
  --tw-bg-opacity: 1;
  background-color: rgb(252 241 237 / var(--tw-bg-opacity));
}

.bg-\[\#FFE6E6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 230 230 / var(--tw-bg-opacity));
}

.bg-\[\#e6f5ea\] {
  --tw-bg-opacity: 1;
  background-color: rgb(230 245 234 / var(--tw-bg-opacity));
}

.bg-\[\#eaeaea\] {
  --tw-bg-opacity: 1;
  background-color: rgb(234 234 234 / var(--tw-bg-opacity));
}

.bg-\[\#ededef\] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 237 239 / var(--tw-bg-opacity));
}

.bg-\[\#f9f9f9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}

.bg-almond {
  --tw-bg-opacity: 1;
  background-color: rgb(235 232 228 / var(--tw-bg-opacity));
}

.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 193 7 / var(--tw-bg-opacity));
}

.bg-amber-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 143 0 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(33 150 243 / var(--tw-bg-opacity));
}

.bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 101 192 / var(--tw-bg-opacity));
}

.bg-blue-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(207 216 220 / var(--tw-bg-opacity));
}

.bg-blue-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 239 241 / var(--tw-bg-opacity));
}

.bg-blue-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 125 139 / var(--tw-bg-opacity));
}

.bg-blue-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 71 79 / var(--tw-bg-opacity));
}

.bg-brown-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(121 85 72 / var(--tw-bg-opacity));
}

.bg-brown-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(78 52 46 / var(--tw-bg-opacity));
}

.bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 188 212 / var(--tw-bg-opacity));
}

.bg-cyan-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 131 143 / var(--tw-bg-opacity));
}

.bg-deep-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 87 34 / var(--tw-bg-opacity));
}

.bg-deep-orange-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(216 67 21 / var(--tw-bg-opacity));
}

.bg-deep-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(103 58 183 / var(--tw-bg-opacity));
}

.bg-deep-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(69 39 160 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(158 158 158 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(66 66 66 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 175 80 / var(--tw-bg-opacity));
}

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(46 125 50 / var(--tw-bg-opacity));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 81 181 / var(--tw-bg-opacity));
}

.bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(40 53 147 / var(--tw-bg-opacity));
}

.bg-inputBg {
  --tw-bg-opacity: 1;
  background-color: rgb(234 234 234 / var(--tw-bg-opacity));
}

.bg-light-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 169 244 / var(--tw-bg-opacity));
}

.bg-light-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 119 189 / var(--tw-bg-opacity));
}

.bg-light-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(139 195 74 / var(--tw-bg-opacity));
}

.bg-light-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(85 139 47 / var(--tw-bg-opacity));
}

.bg-lightBrown {
  --tw-bg-opacity: 1;
  background-color: rgb(247 245 240 / var(--tw-bg-opacity));
}

.bg-lightGrey {
  --tw-bg-opacity: 1;
  background-color: rgb(176 176 176 / var(--tw-bg-opacity));
}

.bg-lime-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(205 220 57 / var(--tw-bg-opacity));
}

.bg-lime-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(158 157 36 / var(--tw-bg-opacity));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 152 0 / var(--tw-bg-opacity));
}

.bg-orange-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 108 0 / var(--tw-bg-opacity));
}

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(233 30 99 / var(--tw-bg-opacity));
}

.bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(173 20 87 / var(--tw-bg-opacity));
}

.bg-primary-24 {
  --tw-bg-opacity: 1;
  background-color: rgb(214 238 221 / var(--tw-bg-opacity));
}

.bg-primary-brand {
  --tw-bg-opacity: 1;
  background-color: rgb(62 174 91 / var(--tw-bg-opacity));
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 39 176 / var(--tw-bg-opacity));
}

.bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(106 27 154 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 67 54 / var(--tw-bg-opacity));
}

.bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(198 40 40 / var(--tw-bg-opacity));
}

.bg-secondary-12 {
  --tw-bg-opacity: 1;
  background-color: rgb(227 229 232 / var(--tw-bg-opacity));
}

.bg-secondary-2 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 251 251 / var(--tw-bg-opacity));
}

.bg-secondary-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}

.bg-secondary-450 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 234 234 / var(--tw-bg-opacity));
}

.bg-secondary-brand\/60 {
  background-color: #50586c99;
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 150 136 / var(--tw-bg-opacity));
}

.bg-teal-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 105 92 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 235 59 / var(--tw-bg-opacity));
}

.bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 168 37 / var(--tw-bg-opacity));
}

.bg-opacity-60 {
  --tw-bg-opacity: .6;
}

.bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.from-amber-600 {
  --tw-gradient-from: #ffb300 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #ffb30000 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue-600 {
  --tw-gradient-from: #1e88e5 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #1e88e500 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-blue-gray-600 {
  --tw-gradient-from: #546e7a var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #546e7a00 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-brown-600 {
  --tw-gradient-from: #6d4c41 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #6d4c4100 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-cyan-600 {
  --tw-gradient-from: #00acc1 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #00acc100 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-deep-orange-600 {
  --tw-gradient-from: #f4511e var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #f4511e00 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-deep-purple-600 {
  --tw-gradient-from: #5e35b1 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #5e35b100 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-gray-600 {
  --tw-gradient-from: #757575 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #75757500 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-green-600 {
  --tw-gradient-from: #43a047 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #43a04700 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-indigo-600 {
  --tw-gradient-from: #3949ab var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #3949ab00 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-light-blue-600 {
  --tw-gradient-from: #039be5 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #039be500 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-light-green-600 {
  --tw-gradient-from: #7cb342 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #7cb34200 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-lime-600 {
  --tw-gradient-from: #c0ca33 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #c0ca3300 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-orange-600 {
  --tw-gradient-from: #fb8c00 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #fb8c0000 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-pink-600 {
  --tw-gradient-from: #d81b60 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #d81b6000 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-600 {
  --tw-gradient-from: #8e24aa var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #8e24aa00 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-red-600 {
  --tw-gradient-from: #e53935 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #e5393500 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-teal-600 {
  --tw-gradient-from: #00897b var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #00897b00 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow-600 {
  --tw-gradient-from: #fdd835 var(--tw-gradient-from-position);
  --tw-gradient-from-position: ;
  --tw-gradient-to: #fdd83500 var(--tw-gradient-from-position);
  --tw-gradient-to-position: ;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-amber-400 {
  --tw-gradient-to: #ffca28 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-blue-400 {
  --tw-gradient-to: #42a5f5 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-blue-gray-400 {
  --tw-gradient-to: #78909c var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-brown-400 {
  --tw-gradient-to: #8d6e63 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-cyan-400 {
  --tw-gradient-to: #26c6da var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-deep-orange-400 {
  --tw-gradient-to: #ff7043 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-deep-purple-400 {
  --tw-gradient-to: #7e57c2 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-gray-400 {
  --tw-gradient-to: #bdbdbd var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-green-400 {
  --tw-gradient-to: #66bb6a var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-indigo-400 {
  --tw-gradient-to: #5c6bc0 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-light-blue-400 {
  --tw-gradient-to: #29b6f6 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-light-green-400 {
  --tw-gradient-to: #9ccc65 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-lime-400 {
  --tw-gradient-to: #d4e157 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-orange-400 {
  --tw-gradient-to: #ffa726 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-pink-400 {
  --tw-gradient-to: #ec407a var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-purple-400 {
  --tw-gradient-to: #ab47bc var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-red-400 {
  --tw-gradient-to: #ef5350 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-teal-400 {
  --tw-gradient-to: #26a69a var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.to-yellow-400 {
  --tw-gradient-to: #ffee58 var(--tw-gradient-to-position);
  --tw-gradient-to-position: ;
}

.bg-clip-border {
  background-clip: border-box;
}

.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[12px\] {
  padding: 12px;
}

.p-\[14px\] {
  padding: 14px;
}

.p-\[16px\] {
  padding: 16px;
}

.p-\[18px\] {
  padding: 18px;
}

.p-\[25px\] {
  padding: 25px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.px-\[18px\] {
  padding-left: 18px;
  padding-right: 18px;
}

.px-\[33px\] {
  padding-left: 33px;
  padding-right: 33px;
}

.px-\[40px\] {
  padding-left: 40px;
  padding-right: 40px;
}

.px-\[80px\] {
  padding-left: 80px;
  padding-right: 80px;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-\[19px\] {
  padding-top: 19px;
  padding-bottom: 19px;
}

.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.\!pr-7 {
  padding-right: 1.75rem !important;
}

.\!pr-9 {
  padding-right: 2.25rem !important;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-56 {
  padding-bottom: 14rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-\[14px\] {
  padding-left: 14px;
}

.pl-\[18px\] {
  padding-left: 18px;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pr-\[18px\] {
  padding-right: 18px;
}

.pt-0 {
  padding-top: 0;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-\[18px\] {
  padding-top: 18px;
}

.pt-\[63px\] {
  padding-top: 63px;
}

.pt-\[9px\] {
  padding-top: 9px;
}

.pt-px {
  padding-top: 1px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-start {
  text-align: start;
}

.align-baseline {
  vertical-align: baseline;
}

.align-middle {
  vertical-align: middle;
}

.font-poppins {
  font-family: Poppins, sans-serif;
}

.font-sans {
  font-family: Roboto, sans-serif;
}

.\!text-\[11px\] {
  font-size: 11px !important;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[11px\] {
  font-size: 11px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[15px\] {
  font-size: 15px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[8px\] {
  font-size: 8px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-xxs {
  font-size: 10px;
  line-height: 14px;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.\!leading-tight {
  line-height: 1.25 !important;
}

.leading-3 {
  line-height: .75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-\[1\.3\] {
  line-height: 1.3;
}

.leading-\[18px\] {
  line-height: 18px;
}

.leading-\[3\.75\] {
  line-height: 3.75;
}

.leading-\[30px\] {
  line-height: 30px;
}

.leading-\[4\.1\] {
  line-height: 4.1;
}

.leading-\[4\.25\] {
  line-height: 4.25;
}

.leading-\[4\.875\] {
  line-height: 4.875;
}

.leading-\[45px\] {
  line-height: 45px;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-0\.5, .tracking-\[0\.05em\] {
  letter-spacing: .05em;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-wider {
  letter-spacing: .05em;
}

.tracking-widest {
  letter-spacing: .1em;
}

.text-\[\#19233D\], .text-\[\#19233d\] {
  --tw-text-opacity: 1;
  color: rgb(25 35 61 / var(--tw-text-opacity));
}

.text-\[\#1F1E1C\] {
  --tw-text-opacity: 1;
  color: rgb(31 30 28 / var(--tw-text-opacity));
}

.text-\[\#238e43\] {
  --tw-text-opacity: 1;
  color: rgb(35 142 67 / var(--tw-text-opacity));
}

.text-\[\#2F80ED\] {
  --tw-text-opacity: 1;
  color: rgb(47 128 237 / var(--tw-text-opacity));
}

.text-\[\#353533\] {
  --tw-text-opacity: 1;
  color: rgb(53 53 51 / var(--tw-text-opacity));
}

.text-\[\#3D3D3D\] {
  --tw-text-opacity: 1;
  color: rgb(61 61 61 / var(--tw-text-opacity));
}

.text-\[\#3eae5b\] {
  --tw-text-opacity: 1;
  color: rgb(62 174 91 / var(--tw-text-opacity));
}

.text-\[\#505050\] {
  --tw-text-opacity: 1;
  color: rgb(80 80 80 / var(--tw-text-opacity));
}

.text-\[\#797877\] {
  --tw-text-opacity: 1;
  color: rgb(121 120 119 / var(--tw-text-opacity));
}

.text-\[\#9a9a9a\] {
  --tw-text-opacity: 1;
  color: rgb(154 154 154 / var(--tw-text-opacity));
}

.text-\[\#EB5757\] {
  --tw-text-opacity: 1;
  color: rgb(235 87 87 / var(--tw-text-opacity));
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgb(255 193 7 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(33 150 243 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(30 136 229 / var(--tw-text-opacity));
}

.text-blue-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(120 144 156 / var(--tw-text-opacity));
}

.text-blue-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(96 125 139 / var(--tw-text-opacity));
}

.text-blue-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(69 90 100 / var(--tw-text-opacity));
}

.text-blue-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(38 50 56 / var(--tw-text-opacity));
}

.text-brown-500 {
  --tw-text-opacity: 1;
  color: rgb(121 85 72 / var(--tw-text-opacity));
}

.text-current {
  color: currentColor;
}

.text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgb(0 188 212 / var(--tw-text-opacity));
}

.text-dark {
  --tw-text-opacity: 1;
  color: rgb(71 79 100 / var(--tw-text-opacity));
}

.text-darkBlack {
  --tw-text-opacity: 1;
  color: rgb(45 55 72 / var(--tw-text-opacity));
}

.text-darkSton {
  --tw-text-opacity: 1;
  color: rgb(115 113 109 / var(--tw-text-opacity));
}

.text-deep-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(255 87 34 / var(--tw-text-opacity));
}

.text-deep-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(103 58 183 / var(--tw-text-opacity));
}

.text-error {
  --tw-text-opacity: 1;
  color: rgb(235 87 87 / var(--tw-text-opacity));
}

.text-fontSton {
  --tw-text-opacity: 1;
  color: rgb(154 154 154 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(189 189 189 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(158 158 158 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(97 97 97 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(66 66 66 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(33 33 33 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(76 175 80 / var(--tw-text-opacity));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(63 81 181 / var(--tw-text-opacity));
}

.text-inherit {
  color: inherit;
}

.text-light-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(3 169 244 / var(--tw-text-opacity));
}

.text-light-green-500 {
  --tw-text-opacity: 1;
  color: rgb(139 195 74 / var(--tw-text-opacity));
}

.text-lightGrey {
  --tw-text-opacity: 1;
  color: rgb(176 176 176 / var(--tw-text-opacity));
}

.text-lime-500 {
  --tw-text-opacity: 1;
  color: rgb(205 220 57 / var(--tw-text-opacity));
}

.text-navyBlue {
  --tw-text-opacity: 1;
  color: rgb(54 56 101 / var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(255 152 0 / var(--tw-text-opacity));
}

.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(233 30 99 / var(--tw-text-opacity));
}

.text-primary-64 {
  --tw-text-opacity: 1;
  color: rgb(101 190 124 / var(--tw-text-opacity));
}

.text-primary-72 {
  --tw-text-opacity: 1;
  color: rgb(81 182 107 / var(--tw-text-opacity));
}

.text-primary-brand {
  --tw-text-opacity: 1;
  color: rgb(62 174 91 / var(--tw-text-opacity));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(156 39 176 / var(--tw-text-opacity));
}

.text-red {
  --tw-text-opacity: 1;
  color: rgb(235 87 87 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(244 67 54 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(229 57 53 / var(--tw-text-opacity));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(198 40 40 / var(--tw-text-opacity));
}

.text-secondary-100 {
  --tw-text-opacity: 1;
  color: rgb(25 35 61 / var(--tw-text-opacity));
}

.text-secondary-48 {
  --tw-text-opacity: 1;
  color: rgb(145 149 162 / var(--tw-text-opacity));
}

.text-stonBlack {
  --tw-text-opacity: 1;
  color: rgb(80 80 80 / var(--tw-text-opacity));
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgb(0 150 136 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(255 235 59 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.placeholder-gray70::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(112 112 112 / var(--tw-placeholder-opacity));
}

.accent-stonBlack {
  accent-color: #505050;
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: .1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-50 {
  opacity: .5;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-cardShadow {
  --tw-shadow: 0px 8px 20px #00000012, 0px 1px 2px #0000000f, 0px 1px 3px #0000001a;
  --tw-shadow-colored: 0px 8px 20px var(--tw-shadow-color), 0px 1px 2px var(--tw-shadow-color), 0px 1px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-dropdown {
  --tw-shadow: 0px 1px 10px #0000000f, 0px 1px 10px #0000001a;
  --tw-shadow-colored: 0px 1px 10px var(--tw-shadow-color), 0px 1px 10px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 rgb(0, 0 / 0, 0);
  --tw-shadow-colored: 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-amber-500\/20 {
  --tw-shadow-color: #ffc10733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-amber-500\/40 {
  --tw-shadow-color: #ffc10766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500\/20 {
  --tw-shadow-color: #2196f333;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-500\/40 {
  --tw-shadow-color: #2196f366;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-gray-500\/10 {
  --tw-shadow-color: #607d8b1a;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-gray-500\/20 {
  --tw-shadow-color: #607d8b33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-blue-gray-500\/40 {
  --tw-shadow-color: #607d8b66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-brown-500\/20 {
  --tw-shadow-color: #79554833;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-brown-500\/40 {
  --tw-shadow-color: #79554866;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500\/20 {
  --tw-shadow-color: #00bcd433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-cyan-500\/40 {
  --tw-shadow-color: #00bcd466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-deep-orange-500\/20 {
  --tw-shadow-color: #ff572233;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-deep-orange-500\/40 {
  --tw-shadow-color: #ff572266;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-deep-purple-500\/20 {
  --tw-shadow-color: #673ab733;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-deep-purple-500\/40 {
  --tw-shadow-color: #673ab766;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500\/20 {
  --tw-shadow-color: #9e9e9e33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-500\/40 {
  --tw-shadow-color: #9e9e9e66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500\/20 {
  --tw-shadow-color: #4caf5033;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-green-500\/40 {
  --tw-shadow-color: #4caf5066;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500\/20 {
  --tw-shadow-color: #3f51b533;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-indigo-500\/40 {
  --tw-shadow-color: #3f51b566;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-light-blue-500\/20 {
  --tw-shadow-color: #03a9f433;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-light-blue-500\/40 {
  --tw-shadow-color: #03a9f466;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-light-green-500\/20 {
  --tw-shadow-color: #8bc34a33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-light-green-500\/40 {
  --tw-shadow-color: #8bc34a66;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/20 {
  --tw-shadow-color: #cddc3933;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-lime-500\/40 {
  --tw-shadow-color: #cddc3966;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500\/20 {
  --tw-shadow-color: #ff980033;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-orange-500\/40 {
  --tw-shadow-color: #ff980066;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/20 {
  --tw-shadow-color: #e91e6333;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-pink-500\/40 {
  --tw-shadow-color: #e91e6366;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/20 {
  --tw-shadow-color: #9c27b033;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-purple-500\/40 {
  --tw-shadow-color: #9c27b066;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/20 {
  --tw-shadow-color: #f4433633;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/40 {
  --tw-shadow-color: #f4433666;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500\/20 {
  --tw-shadow-color: #00968833;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-teal-500\/40 {
  --tw-shadow-color: #00968866;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500\/20 {
  --tw-shadow-color: #ffeb3b33;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-yellow-500\/40 {
  --tw-shadow-color: #ffeb3b66;
  --tw-shadow: var(--tw-shadow-colored);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.outline-0 {
  outline-width: 0;
}

.outline-\[\#DADADA\] {
  outline-color: #dadada;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-2xl {
  --tw-backdrop-blur: blur(40px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-saturate-200 {
  --tw-backdrop-saturate: saturate(2);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\!duration-500 {
  transition-duration: .5s !important;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.scrollbar.\!overflow-x-hidden {
  overflow-x: hidden !important;
}

.scrollbar.overflow-x-hidden {
  overflow-x: hidden;
}

.scrollbar-thin {
  --scrollbar-track: initial;
  --scrollbar-thumb: initial;
  --scrollbar-corner: initial;
  --scrollbar-track-hover: var(--scrollbar-track);
  --scrollbar-thumb-hover: var(--scrollbar-thumb);
  --scrollbar-corner-hover: var(--scrollbar-corner);
  --scrollbar-track-active: var(--scrollbar-track-hover);
  --scrollbar-thumb-active: var(--scrollbar-thumb-hover);
  --scrollbar-corner-active: var(--scrollbar-corner-hover);
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
  overflow: overlay;
}

.scrollbar-thin.overflow-x-hidden {
  overflow-x: hidden;
}

.scrollbar-thin.\!overflow-x-hidden {
  overflow-x: hidden !important;
}

.scrollbar-thin.overflow-y-hidden {
  overflow-y: hidden;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track);
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
}

.scrollbar-thin::-webkit-scrollbar-corner {
  background-color: var(--scrollbar-corner);
}

.scrollbar-thin::-webkit-scrollbar-track:hover {
  background-color: var(--scrollbar-track-hover);
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover);
}

.scrollbar-thin::-webkit-scrollbar-corner:hover {
  background-color: var(--scrollbar-corner-hover);
}

.scrollbar-thin::-webkit-scrollbar-track:active {
  background-color: var(--scrollbar-track-active);
}

.scrollbar-thin::-webkit-scrollbar-thumb:active {
  background-color: var(--scrollbar-thumb-active);
}

.scrollbar-thin::-webkit-scrollbar-corner:active {
  background-color: var(--scrollbar-corner-active);
}

.scrollbar-thin {
  scrollbar-width: thin;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scrollbar-track-transparent {
  --scrollbar-track: transparent !important;
}

.scrollbar-thumb-inputBg {
  --scrollbar-thumb: #eaeaea !important;
}

.\[animation-delay\:-0\.9s\] {
  animation-delay: -.9s;
}

.\[animation-delay\:-1\.1s\] {
  animation-delay: -1.1s;
}

.\[box-shadow\:0px_0px_1px_rgba\(40\,_41\,_61\,_0\.04\)\,_0px_2px_4px_rgba\(96\,_97\,_112\,_0\.16\)\] {
  box-shadow: 0 0 1px #28293d0a, 0 2px 4px #60617029;
}

.\[box-shadow\:0px_2px_8px_rgba\(40\,_41\,_61\,_0\.08\)\,_0px_20px_32px_rgba\(96\,_97\,_112\,_0\.24\)\] {
  box-shadow: 0 2px 8px #28293d14, 0 20px 32px #6061703d;
}

.\[box-shadow\:0px_4px_16px_8px_rgba\(80\,_80\,_80\,_0\.15\)\] {
  box-shadow: 0 4px 16px 8px #50505026;
}

.\[box-shadow\:inset_1px_3px_10px_rgba\(0\,_0\,_0\,_0\.15\)\] {
  box-shadow: inset 1px 3px 10px #00000026;
}

.\[decoration\:none\] {
  decoration: none;
}

.\[display\:inherit\] {
  display: inherit;
}

body {
  color: #474f64;
  font-size: 16px;
  font-weight: 400;
}

.canvas-container canvas {
  max-width: 500px;
  width: 100% !important;
  height: 100% !important;
}

select {
  background: url("select-arrow.69d5b47f.svg") right 12px center no-repeat;
  padding-right: 26px !important;
}

img {
  max-width: 440px;
}

.sidebar-link.active {
  color: #3eae5b;
  background: #d8efde;
  border-radius: 4px 0 0 4px;
}

[type="number"] {
  -moz-appearance: textfield;
}

.placeholder\:italic::placeholder {
  font-style: italic;
}

.placeholder\:text-\[\#656565\]::placeholder {
  --tw-text-opacity: 1;
  color: rgb(101 101 101 / var(--tw-text-opacity));
}

.placeholder\:text-\[\#9195a2\]::placeholder {
  --tw-text-opacity: 1;
  color: rgb(145 149 162 / var(--tw-text-opacity));
}

.before\:pointer-events-none:before {
  content: var(--tw-content);
  pointer-events: none;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:left-2\/4:before {
  content: var(--tw-content);
  left: 50%;
}

.before\:top-2\/4:before {
  content: var(--tw-content);
  top: 50%;
}

.before\:mr-1:before {
  content: var(--tw-content);
  margin-right: .25rem;
}

.before\:mt-20:before {
  content: var(--tw-content);
  margin-top: 5rem;
}

.before\:mt-\[6\.5px\]:before {
  content: var(--tw-content);
  margin-top: 6.5px;
}

.before\:box-border:before {
  content: var(--tw-content);
  box-sizing: border-box;
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:h-1:before {
  content: var(--tw-content);
  height: .25rem;
}

.before\:h-1\.5:before {
  content: var(--tw-content);
  height: .375rem;
}

.before\:h-10:before {
  content: var(--tw-content);
  height: 2.5rem;
}

.before\:h-12:before {
  content: var(--tw-content);
  height: 3rem;
}

.before\:w-10:before {
  content: var(--tw-content);
  width: 2.5rem;
}

.before\:w-12:before {
  content: var(--tw-content);
  width: 3rem;
}

.before\:w-2:before {
  content: var(--tw-content);
  width: .5rem;
}

.before\:w-2\.5:before {
  content: var(--tw-content);
  width: .625rem;
}

.before\:max-w-xs:before {
  content: var(--tw-content);
  max-width: 20rem;
}

.before\:-translate-x-2\/4:before {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-translate-y-2\/4:before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:rounded-full:before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:rounded-tl-md:before {
  content: var(--tw-content);
  border-top-left-radius: .375rem;
}

.before\:border-l:before {
  content: var(--tw-content);
  border-left-width: 1px;
}

.before\:border-l-2:before {
  content: var(--tw-content);
  border-left-width: 2px;
}

.before\:border-t:before {
  content: var(--tw-content);
  border-top-width: 1px;
}

.before\:border-t-2:before {
  content: var(--tw-content);
  border-top-width: 2px;
}

.before\:\!border-blue-gray-200:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(176 190 197 / var(--tw-border-opacity)) !important;
}

.before\:border-amber-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 193 7 / var(--tw-border-opacity));
}

.before\:border-blue-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(33 150 243 / var(--tw-border-opacity));
}

.before\:border-blue-gray-200:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(176 190 197 / var(--tw-border-opacity));
}

.before\:border-blue-gray-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(96 125 139 / var(--tw-border-opacity));
}

.before\:border-brown-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(121 85 72 / var(--tw-border-opacity));
}

.before\:border-cyan-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(0 188 212 / var(--tw-border-opacity));
}

.before\:border-deep-orange-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 87 34 / var(--tw-border-opacity));
}

.before\:border-deep-purple-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(103 58 183 / var(--tw-border-opacity));
}

.before\:border-gray-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(158 158 158 / var(--tw-border-opacity));
}

.before\:border-green-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(76 175 80 / var(--tw-border-opacity));
}

.before\:border-indigo-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(63 81 181 / var(--tw-border-opacity));
}

.before\:border-light-blue-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(3 169 244 / var(--tw-border-opacity));
}

.before\:border-light-green-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(139 195 74 / var(--tw-border-opacity));
}

.before\:border-lime-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(205 220 57 / var(--tw-border-opacity));
}

.before\:border-orange-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 152 0 / var(--tw-border-opacity));
}

.before\:border-pink-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(233 30 99 / var(--tw-border-opacity));
}

.before\:border-purple-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(156 39 176 / var(--tw-border-opacity));
}

.before\:border-red-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(244 67 54 / var(--tw-border-opacity));
}

.before\:border-teal-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(0 150 136 / var(--tw-border-opacity));
}

.before\:border-transparent:before {
  content: var(--tw-content);
  border-color: #0000;
}

.before\:border-yellow-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 235 59 / var(--tw-border-opacity));
}

.before\:border-l-transparent:before {
  content: var(--tw-content);
  border-left-color: #0000;
}

.before\:border-t-transparent:before {
  content: var(--tw-content);
  border-top-color: #0000;
}

.before\:bg-blue-gray-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(96 125 139 / var(--tw-bg-opacity));
}

.before\:bg-gray-900:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(33 33 33 / var(--tw-bg-opacity));
}

.before\:p-2:before {
  content: var(--tw-content);
  padding: .5rem;
}

.before\:text-xs:before {
  content: var(--tw-content);
  font-size: .75rem;
  line-height: 1rem;
}

.before\:text-white:before {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.before\:opacity-0:before {
  content: var(--tw-content);
  opacity: 0;
}

.before\:shadow-sm:before {
  content: var(--tw-content);
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.before\:transition-all:before {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.before\:transition-opacity:before {
  content: var(--tw-content);
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.before\:content-\[attr\(tooltip\)\]:before {
  --tw-content: attr(tooltip);
  content: var(--tw-content);
}

.after\:pointer-events-none:after {
  content: var(--tw-content);
  pointer-events: none;
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:-bottom-0:after {
  content: var(--tw-content);
  bottom: 0;
}

.after\:-bottom-1:after {
  content: var(--tw-content);
  bottom: -.25rem;
}

.after\:-bottom-1\.5:after {
  content: var(--tw-content);
  bottom: -.375rem;
}

.after\:-bottom-2:after {
  content: var(--tw-content);
  bottom: -.5rem;
}

.after\:-bottom-2\.5:after {
  content: var(--tw-content);
  bottom: -.625rem;
}

.after\:ml-1:after {
  content: var(--tw-content);
  margin-left: .25rem;
}

.after\:mt-\[6\.5px\]:after {
  content: var(--tw-content);
  margin-top: 6.5px;
}

.after\:box-border:after {
  content: var(--tw-content);
  box-sizing: border-box;
}

.after\:block:after {
  content: var(--tw-content);
  display: block;
}

.after\:h-1:after {
  content: var(--tw-content);
  height: .25rem;
}

.after\:h-1\.5:after {
  content: var(--tw-content);
  height: .375rem;
}

.after\:w-2:after {
  content: var(--tw-content);
  width: .5rem;
}

.after\:w-2\.5:after {
  content: var(--tw-content);
  width: .625rem;
}

.after\:w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.after\:flex-grow:after {
  content: var(--tw-content);
  flex-grow: 1;
}

.after\:scale-x-0:after {
  content: var(--tw-content);
  --tw-scale-x: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:scale-x-100:after {
  content: var(--tw-content);
  --tw-scale-x: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rounded-tr-md:after {
  content: var(--tw-content);
  border-top-right-radius: .375rem;
}

.after\:border-b-2:after {
  content: var(--tw-content);
  border-bottom-width: 2px;
}

.after\:border-r:after {
  content: var(--tw-content);
  border-right-width: 1px;
}

.after\:border-r-2:after {
  content: var(--tw-content);
  border-right-width: 2px;
}

.after\:border-t:after {
  content: var(--tw-content);
  border-top-width: 1px;
}

.after\:border-t-2:after {
  content: var(--tw-content);
  border-top-width: 2px;
}

.after\:\!border-blue-gray-200:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(176 190 197 / var(--tw-border-opacity)) !important;
}

.after\:border-amber-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 193 7 / var(--tw-border-opacity));
}

.after\:border-blue-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(33 150 243 / var(--tw-border-opacity));
}

.after\:border-blue-gray-200:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(176 190 197 / var(--tw-border-opacity));
}

.after\:border-blue-gray-50:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(236 239 241 / var(--tw-border-opacity));
}

.after\:border-blue-gray-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(96 125 139 / var(--tw-border-opacity));
}

.after\:border-brown-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(121 85 72 / var(--tw-border-opacity));
}

.after\:border-cyan-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(0 188 212 / var(--tw-border-opacity));
}

.after\:border-deep-orange-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 87 34 / var(--tw-border-opacity));
}

.after\:border-deep-purple-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(103 58 183 / var(--tw-border-opacity));
}

.after\:border-gray-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(158 158 158 / var(--tw-border-opacity));
}

.after\:border-green-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(76 175 80 / var(--tw-border-opacity));
}

.after\:border-indigo-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(63 81 181 / var(--tw-border-opacity));
}

.after\:border-light-blue-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(3 169 244 / var(--tw-border-opacity));
}

.after\:border-light-green-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(139 195 74 / var(--tw-border-opacity));
}

.after\:border-lime-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(205 220 57 / var(--tw-border-opacity));
}

.after\:border-orange-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 152 0 / var(--tw-border-opacity));
}

.after\:border-pink-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(233 30 99 / var(--tw-border-opacity));
}

.after\:border-purple-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(156 39 176 / var(--tw-border-opacity));
}

.after\:border-red-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(244 67 54 / var(--tw-border-opacity));
}

.after\:border-teal-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(0 150 136 / var(--tw-border-opacity));
}

.after\:border-transparent:after {
  content: var(--tw-content);
  border-color: #0000;
}

.after\:border-yellow-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 235 59 / var(--tw-border-opacity));
}

.after\:border-r-transparent:after {
  content: var(--tw-content);
  border-right-color: #0000;
}

.after\:border-t-transparent:after {
  content: var(--tw-content);
  border-top-color: #0000;
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:transition-transform:after {
  content: var(--tw-content);
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:duration-300:after {
  content: var(--tw-content);
  transition-duration: .3s;
}

.last\:delay-\[-0\.9s\]:last-child {
  transition-delay: -.9s;
}

.even\:delay-\[-1\.1s\]:nth-child(2n) {
  transition-delay: -1.1s;
}

.checked\:border-amber-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(255 193 7 / var(--tw-border-opacity));
}

.checked\:border-blue-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(33 150 243 / var(--tw-border-opacity));
}

.checked\:border-blue-gray-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(96 125 139 / var(--tw-border-opacity));
}

.checked\:border-brown-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(121 85 72 / var(--tw-border-opacity));
}

.checked\:border-cyan-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(0 188 212 / var(--tw-border-opacity));
}

.checked\:border-deep-orange-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(255 87 34 / var(--tw-border-opacity));
}

.checked\:border-deep-purple-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(103 58 183 / var(--tw-border-opacity));
}

.checked\:border-gray-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(158 158 158 / var(--tw-border-opacity));
}

.checked\:border-green-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(76 175 80 / var(--tw-border-opacity));
}

.checked\:border-indigo-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(63 81 181 / var(--tw-border-opacity));
}

.checked\:border-light-blue-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(3 169 244 / var(--tw-border-opacity));
}

.checked\:border-light-green-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(139 195 74 / var(--tw-border-opacity));
}

.checked\:border-lime-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(205 220 57 / var(--tw-border-opacity));
}

.checked\:border-orange-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(255 152 0 / var(--tw-border-opacity));
}

.checked\:border-pink-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(233 30 99 / var(--tw-border-opacity));
}

.checked\:border-purple-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(156 39 176 / var(--tw-border-opacity));
}

.checked\:border-red-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(244 67 54 / var(--tw-border-opacity));
}

.checked\:border-teal-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(0 150 136 / var(--tw-border-opacity));
}

.checked\:border-yellow-500:checked {
  --tw-border-opacity: 1;
  border-color: rgb(255 235 59 / var(--tw-border-opacity));
}

.checked\:bg-amber-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(255 193 7 / var(--tw-bg-opacity));
}

.checked\:bg-blue-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(33 150 243 / var(--tw-bg-opacity));
}

.checked\:bg-blue-gray-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(96 125 139 / var(--tw-bg-opacity));
}

.checked\:bg-brown-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(121 85 72 / var(--tw-bg-opacity));
}

.checked\:bg-cyan-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(0 188 212 / var(--tw-bg-opacity));
}

.checked\:bg-deep-orange-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(255 87 34 / var(--tw-bg-opacity));
}

.checked\:bg-deep-purple-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(103 58 183 / var(--tw-bg-opacity));
}

.checked\:bg-gray-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(158 158 158 / var(--tw-bg-opacity));
}

.checked\:bg-green-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(76 175 80 / var(--tw-bg-opacity));
}

.checked\:bg-indigo-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(63 81 181 / var(--tw-bg-opacity));
}

.checked\:bg-light-blue-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(3 169 244 / var(--tw-bg-opacity));
}

.checked\:bg-light-green-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(139 195 74 / var(--tw-bg-opacity));
}

.checked\:bg-lime-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(205 220 57 / var(--tw-bg-opacity));
}

.checked\:bg-orange-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(255 152 0 / var(--tw-bg-opacity));
}

.checked\:bg-pink-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(233 30 99 / var(--tw-bg-opacity));
}

.checked\:bg-purple-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(156 39 176 / var(--tw-bg-opacity));
}

.checked\:bg-red-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(244 67 54 / var(--tw-bg-opacity));
}

.checked\:bg-teal-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(0 150 136 / var(--tw-bg-opacity));
}

.checked\:bg-yellow-500:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(255 235 59 / var(--tw-bg-opacity));
}

.checked\:before\:bg-amber-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 193 7 / var(--tw-bg-opacity));
}

.checked\:before\:bg-blue-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(33 150 243 / var(--tw-bg-opacity));
}

.checked\:before\:bg-blue-gray-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(96 125 139 / var(--tw-bg-opacity));
}

.checked\:before\:bg-brown-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(121 85 72 / var(--tw-bg-opacity));
}

.checked\:before\:bg-cyan-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 188 212 / var(--tw-bg-opacity));
}

.checked\:before\:bg-deep-orange-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 87 34 / var(--tw-bg-opacity));
}

.checked\:before\:bg-deep-purple-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(103 58 183 / var(--tw-bg-opacity));
}

.checked\:before\:bg-gray-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(158 158 158 / var(--tw-bg-opacity));
}

.checked\:before\:bg-green-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(76 175 80 / var(--tw-bg-opacity));
}

.checked\:before\:bg-indigo-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(63 81 181 / var(--tw-bg-opacity));
}

.checked\:before\:bg-light-blue-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(3 169 244 / var(--tw-bg-opacity));
}

.checked\:before\:bg-light-green-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(139 195 74 / var(--tw-bg-opacity));
}

.checked\:before\:bg-lime-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(205 220 57 / var(--tw-bg-opacity));
}

.checked\:before\:bg-orange-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 152 0 / var(--tw-bg-opacity));
}

.checked\:before\:bg-pink-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(233 30 99 / var(--tw-bg-opacity));
}

.checked\:before\:bg-purple-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(156 39 176 / var(--tw-bg-opacity));
}

.checked\:before\:bg-red-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(244 67 54 / var(--tw-bg-opacity));
}

.checked\:before\:bg-teal-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 150 136 / var(--tw-bg-opacity));
}

.checked\:before\:bg-yellow-500:checked:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 235 59 / var(--tw-bg-opacity));
}

.placeholder-shown\:border:placeholder-shown {
  border-width: 1px;
}

.placeholder-shown\:border-blue-gray-200:placeholder-shown {
  --tw-border-opacity: 1;
  border-color: rgb(176 190 197 / var(--tw-border-opacity));
}

.placeholder-shown\:border-green-500:placeholder-shown {
  --tw-border-opacity: 1;
  border-color: rgb(76 175 80 / var(--tw-border-opacity));
}

.placeholder-shown\:border-red-500:placeholder-shown {
  --tw-border-opacity: 1;
  border-color: rgb(244 67 54 / var(--tw-border-opacity));
}

.placeholder-shown\:border-t-blue-gray-200:placeholder-shown {
  --tw-border-opacity: 1;
  border-top-color: rgb(176 190 197 / var(--tw-border-opacity));
}

.placeholder-shown\:border-t-green-500:placeholder-shown {
  --tw-border-opacity: 1;
  border-top-color: rgb(76 175 80 / var(--tw-border-opacity));
}

.placeholder-shown\:border-t-red-500:placeholder-shown {
  --tw-border-opacity: 1;
  border-top-color: rgb(244 67 54 / var(--tw-border-opacity));
}

.focus\:border-2:focus {
  border-width: 2px;
}

.focus\:border-amber-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(255 193 7 / var(--tw-border-opacity));
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(33 150 243 / var(--tw-border-opacity));
}

.focus\:border-blue-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(96 125 139 / var(--tw-border-opacity));
}

.focus\:border-brown-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(121 85 72 / var(--tw-border-opacity));
}

.focus\:border-cyan-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(0 188 212 / var(--tw-border-opacity));
}

.focus\:border-deep-orange-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(255 87 34 / var(--tw-border-opacity));
}

.focus\:border-deep-purple-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(103 58 183 / var(--tw-border-opacity));
}

.focus\:border-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(158 158 158 / var(--tw-border-opacity));
}

.focus\:border-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(76 175 80 / var(--tw-border-opacity));
}

.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(63 81 181 / var(--tw-border-opacity));
}

.focus\:border-light-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(3 169 244 / var(--tw-border-opacity));
}

.focus\:border-light-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(139 195 74 / var(--tw-border-opacity));
}

.focus\:border-lime-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(205 220 57 / var(--tw-border-opacity));
}

.focus\:border-orange-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(255 152 0 / var(--tw-border-opacity));
}

.focus\:border-pink-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(233 30 99 / var(--tw-border-opacity));
}

.focus\:border-primary-brand:focus {
  --tw-border-opacity: 1;
  border-color: rgb(62 174 91 / var(--tw-border-opacity));
}

.focus\:border-purple-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(156 39 176 / var(--tw-border-opacity));
}

.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(244 67 54 / var(--tw-border-opacity));
}

.focus\:border-stonBlack:focus {
  --tw-border-opacity: 1;
  border-color: rgb(80 80 80 / var(--tw-border-opacity));
}

.focus\:border-teal-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(0 150 136 / var(--tw-border-opacity));
}

.focus\:border-yellow-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(255 235 59 / var(--tw-border-opacity));
}

.focus\:border-l-primary-brand:focus {
  --tw-border-opacity: 1;
  border-left-color: rgb(62 174 91 / var(--tw-border-opacity));
}

.focus\:border-r-primary-brand:focus {
  --tw-border-opacity: 1;
  border-right-color: rgb(62 174 91 / var(--tw-border-opacity));
}

.focus\:border-t-transparent:focus {
  border-top-color: #0000;
}

.focus\:bg-blue-gray-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(236 239 241 / var(--tw-bg-opacity));
}

.focus\:bg-transparent:focus {
  background-color: #0000;
}

.focus\:bg-opacity-80:focus {
  --tw-bg-opacity: .8;
}

.focus\:text-blue-gray-500:focus {
  --tw-text-opacity: 1;
  color: rgb(96 125 139 / var(--tw-text-opacity));
}

.focus\:text-blue-gray-900:focus {
  --tw-text-opacity: 1;
  color: rgb(38 50 56 / var(--tw-text-opacity));
}

.focus\:opacity-\[0\.85\]:focus {
  opacity: .85;
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 rgb(0, 0 / 0, 0);
  --tw-shadow-colored: 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:outline-0:focus {
  outline-width: 0;
}

.focus\:outline-green-500:focus {
  outline-color: #4caf50;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-amber-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 224 130 / var(--tw-ring-opacity));
}

.focus\:ring-blue-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(144 202 249 / var(--tw-ring-opacity));
}

.focus\:ring-blue-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(176 190 197 / var(--tw-ring-opacity));
}

.focus\:ring-brown-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(188 170 164 / var(--tw-ring-opacity));
}

.focus\:ring-cyan-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(128 222 234 / var(--tw-ring-opacity));
}

.focus\:ring-deep-orange-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 171 145 / var(--tw-ring-opacity));
}

.focus\:ring-deep-purple-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(179 157 219 / var(--tw-ring-opacity));
}

.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(238 238 238 / var(--tw-ring-opacity));
}

.focus\:ring-green-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(165 214 167 / var(--tw-ring-opacity));
}

.focus\:ring-indigo-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(159 168 218 / var(--tw-ring-opacity));
}

.focus\:ring-light-blue-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(129 212 250 / var(--tw-ring-opacity));
}

.focus\:ring-light-green-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(197 225 165 / var(--tw-ring-opacity));
}

.focus\:ring-lime-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(230 238 156 / var(--tw-ring-opacity));
}

.focus\:ring-orange-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 204 128 / var(--tw-ring-opacity));
}

.focus\:ring-pink-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(244 143 177 / var(--tw-ring-opacity));
}

.focus\:ring-purple-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(206 147 216 / var(--tw-ring-opacity));
}

.focus\:ring-red-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 154 154 / var(--tw-ring-opacity));
}

.focus\:ring-teal-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(128 203 196 / var(--tw-ring-opacity));
}

.focus\:ring-white\/50:focus {
  --tw-ring-color: #ffffff80;
}

.focus\:ring-yellow-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 245 157 / var(--tw-ring-opacity));
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:resize-none:disabled {
  resize: none;
}

.disabled\:border-0:disabled {
  border-width: 0;
}

.disabled\:bg-blue-gray-50:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(236 239 241 / var(--tw-bg-opacity));
}

.disabled\:bg-transparent:disabled {
  background-color: #0000;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.disabled\:shadow-none:disabled {
  --tw-shadow: 0 0 rgb(0, 0 / 0, 0);
  --tw-shadow-colored: 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:flex {
  display: flex;
}

.group:hover .group-hover\:hidden {
  display: none;
}

.peer:checked ~ .peer-checked\:translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:border-amber-500 {
  --tw-border-opacity: 1;
  border-color: rgb(255 193 7 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(33 150 243 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-blue-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(96 125 139 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-brown-500 {
  --tw-border-opacity: 1;
  border-color: rgb(121 85 72 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-cyan-500 {
  --tw-border-opacity: 1;
  border-color: rgb(0 188 212 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-deep-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgb(255 87 34 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-deep-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(103 58 183 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(158 158 158 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(76 175 80 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(63 81 181 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-light-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(3 169 244 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-light-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(139 195 74 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-lime-500 {
  --tw-border-opacity: 1;
  border-color: rgb(205 220 57 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgb(255 152 0 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgb(233 30 99 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(156 39 176 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(244 67 54 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-teal-500 {
  --tw-border-opacity: 1;
  border-color: rgb(0 150 136 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(255 235 59 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:opacity-100 {
  opacity: 1;
}

.peer:checked ~ .peer-checked\:before\:bg-amber-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 193 7 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-blue-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(33 150 243 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-blue-gray-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(96 125 139 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-brown-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(121 85 72 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-cyan-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 188 212 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-deep-orange-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 87 34 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-deep-purple-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(103 58 183 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-gray-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(158 158 158 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-green-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(76 175 80 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-indigo-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(63 81 181 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-light-blue-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(3 169 244 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-light-green-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(139 195 74 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-lime-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(205 220 57 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-orange-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 152 0 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-pink-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(233 30 99 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-purple-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(156 39 176 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-red-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(244 67 54 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-teal-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(0 150 136 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:before\:bg-yellow-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 235 59 / var(--tw-bg-opacity));
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:leading-\[3\.75\] {
  line-height: 3.75;
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:leading-\[4\.1\] {
  line-height: 4.1;
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:leading-\[4\.25\] {
  line-height: 4.25;
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:leading-\[4\.875\] {
  line-height: 4.875;
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:leading-tight {
  line-height: 1.25;
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:text-blue-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(96 125 139 / var(--tw-text-opacity));
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(76 175 80 / var(--tw-text-opacity));
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(244 67 54 / var(--tw-text-opacity));
}

.peer:placeholder-shown ~ .peer-placeholder-shown\:before\:border-transparent:before, .peer:placeholder-shown ~ .peer-placeholder-shown\:after\:border-transparent:after {
  content: var(--tw-content);
  border-color: #0000;
}

.peer:focus ~ .peer-focus\:text-\[11px\] {
  font-size: 11px;
}

.peer:focus ~ .peer-focus\:text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.peer:focus ~ .peer-focus\:leading-tight {
  line-height: 1.25;
}

.peer:focus ~ .peer-focus\:text-amber-500 {
  --tw-text-opacity: 1;
  color: rgb(255 193 7 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(33 150 243 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-blue-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(96 125 139 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-brown-500 {
  --tw-text-opacity: 1;
  color: rgb(121 85 72 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgb(0 188 212 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-deep-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(255 87 34 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-deep-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(103 58 183 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(158 158 158 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(76 175 80 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(63 81 181 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-light-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(3 169 244 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-light-green-500 {
  --tw-text-opacity: 1;
  color: rgb(139 195 74 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-lime-500 {
  --tw-text-opacity: 1;
  color: rgb(205 220 57 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(255 152 0 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(233 30 99 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(156 39 176 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(244 67 54 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-teal-500 {
  --tw-text-opacity: 1;
  color: rgb(0 150 136 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(255 235 59 / var(--tw-text-opacity));
}

.peer:focus ~ .peer-focus\:before\:border-l-2:before {
  content: var(--tw-content);
  border-left-width: 2px;
}

.peer:focus ~ .peer-focus\:before\:border-t-2:before {
  content: var(--tw-content);
  border-top-width: 2px;
}

.peer:focus ~ .peer-focus\:before\:\!border-amber-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 193 7 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-blue-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(33 150 243 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-blue-gray-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(96 125 139 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-brown-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(121 85 72 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-cyan-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(0 188 212 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-deep-orange-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 87 34 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-deep-purple-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(103 58 183 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-gray-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(158 158 158 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-green-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(76 175 80 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-indigo-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(63 81 181 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-light-blue-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(3 169 244 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-light-green-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(139 195 74 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-lime-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(205 220 57 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-orange-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 152 0 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-pink-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(233 30 99 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-purple-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(156 39 176 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-red-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(244 67 54 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-teal-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(0 150 136 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:\!border-yellow-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 235 59 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:before\:border-green-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(76 175 80 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:before\:border-red-500:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(244 67 54 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:scale-x-100:after {
  content: var(--tw-content);
  --tw-scale-x: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:focus ~ .peer-focus\:after\:border-r-2:after {
  content: var(--tw-content);
  border-right-width: 2px;
}

.peer:focus ~ .peer-focus\:after\:border-t-2:after {
  content: var(--tw-content);
  border-top-width: 2px;
}

.peer:focus ~ .peer-focus\:after\:\!border-amber-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 193 7 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-blue-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(33 150 243 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-blue-gray-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(96 125 139 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-brown-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(121 85 72 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-cyan-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(0 188 212 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-deep-orange-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 87 34 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-deep-purple-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(103 58 183 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-gray-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(158 158 158 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-green-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(76 175 80 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-indigo-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(63 81 181 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-light-blue-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(3 169 244 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-light-green-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(139 195 74 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-lime-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(205 220 57 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-orange-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 152 0 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-pink-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(233 30 99 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-purple-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(156 39 176 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-red-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(244 67 54 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-teal-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(0 150 136 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:\!border-yellow-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 235 59 / var(--tw-border-opacity)) !important;
}

.peer:focus ~ .peer-focus\:after\:border-amber-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 193 7 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-blue-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(33 150 243 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-blue-gray-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(96 125 139 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-brown-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(121 85 72 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-cyan-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(0 188 212 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-deep-orange-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 87 34 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-deep-purple-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(103 58 183 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-gray-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(158 158 158 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-green-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(76 175 80 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-indigo-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(63 81 181 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-light-blue-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(3 169 244 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-light-green-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(139 195 74 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-lime-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(205 220 57 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-orange-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 152 0 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-pink-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(233 30 99 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-purple-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(156 39 176 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-red-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(244 67 54 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-teal-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(0 150 136 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:after\:border-yellow-500:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 235 59 / var(--tw-border-opacity));
}

.peer:disabled ~ .peer-disabled\:text-blue-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(120 144 156 / var(--tw-text-opacity));
}

.peer:disabled ~ .peer-disabled\:text-transparent {
  color: #0000;
}

.peer:disabled ~ .peer-disabled\:before\:border-transparent:before, .peer:disabled ~ .peer-disabled\:after\:border-transparent:after {
  content: var(--tw-content);
  border-color: #0000;
}

.peer:disabled:placeholder-shown ~ .peer-disabled\:peer-placeholder-shown\:text-blue-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(96 125 139 / var(--tw-text-opacity));
}

.hover\:block:hover {
  display: block;
}

.hover\:border-primary-brand:hover {
  --tw-border-opacity: 1;
  border-color: rgb(62 174 91 / var(--tw-border-opacity));
}

.hover\:bg-amber-500\/10:hover {
  background-color: #ffc1071a;
}

.hover\:bg-amber-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 111 0 / var(--tw-bg-opacity));
}

.hover\:bg-blue-500\/10:hover {
  background-color: #2196f31a;
}

.hover\:bg-blue-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(13 71 161 / var(--tw-bg-opacity));
}

.hover\:bg-blue-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 239 241 / var(--tw-bg-opacity));
}

.hover\:bg-blue-gray-500\/10:hover {
  background-color: #607d8b1a;
}

.hover\:bg-blue-gray-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(38 50 56 / var(--tw-bg-opacity));
}

.hover\:bg-brown-500\/10:hover {
  background-color: #7955481a;
}

.hover\:bg-brown-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(62 39 35 / var(--tw-bg-opacity));
}

.hover\:bg-cyan-500\/10:hover {
  background-color: #00bcd41a;
}

.hover\:bg-cyan-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 96 100 / var(--tw-bg-opacity));
}

.hover\:bg-deep-orange-500\/10:hover {
  background-color: #ff57221a;
}

.hover\:bg-deep-orange-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(191 54 12 / var(--tw-bg-opacity));
}

.hover\:bg-deep-purple-500\/10:hover {
  background-color: #673ab71a;
}

.hover\:bg-deep-purple-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(49 27 146 / var(--tw-bg-opacity));
}

.hover\:bg-gray-500\/10:hover {
  background-color: #9e9e9e1a;
}

.hover\:bg-gray-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(33 33 33 / var(--tw-bg-opacity));
}

.hover\:bg-green-500\/10:hover {
  background-color: #4caf501a;
}

.hover\:bg-green-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(27 94 32 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-500\/10:hover {
  background-color: #3f51b51a;
}

.hover\:bg-indigo-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(26 35 126 / var(--tw-bg-opacity));
}

.hover\:bg-light-blue-500\/10:hover {
  background-color: #03a9f41a;
}

.hover\:bg-light-blue-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(1 87 155 / var(--tw-bg-opacity));
}

.hover\:bg-light-green-500\/10:hover {
  background-color: #8bc34a1a;
}

.hover\:bg-light-green-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(51 105 30 / var(--tw-bg-opacity));
}

.hover\:bg-lightGreen:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(222 255 231 / var(--tw-bg-opacity));
}

.hover\:bg-lime-500\/10:hover {
  background-color: #cddc391a;
}

.hover\:bg-lime-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(130 119 23 / var(--tw-bg-opacity));
}

.hover\:bg-orange-500\/10:hover {
  background-color: #ff98001a;
}

.hover\:bg-orange-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(230 81 0 / var(--tw-bg-opacity));
}

.hover\:bg-pink-500\/10:hover {
  background-color: #e91e631a;
}

.hover\:bg-pink-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(136 14 79 / var(--tw-bg-opacity));
}

.hover\:bg-primary-brand:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(62 174 91 / var(--tw-bg-opacity));
}

.hover\:bg-primary-brand\/40:hover {
  background-color: #3eae5b66;
}

.hover\:bg-purple-500\/10:hover {
  background-color: #9c27b01a;
}

.hover\:bg-purple-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(74 20 140 / var(--tw-bg-opacity));
}

.hover\:bg-red-500\/10:hover {
  background-color: #f443361a;
}

.hover\:bg-red-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(183 28 28 / var(--tw-bg-opacity));
}

.hover\:bg-teal-500\/10:hover {
  background-color: #0096881a;
}

.hover\:bg-teal-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 77 64 / var(--tw-bg-opacity));
}

.hover\:bg-transparent:hover {
  background-color: #0000;
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-white\/10:hover {
  background-color: #ffffff1a;
}

.hover\:bg-yellow-500\/10:hover {
  background-color: #ffeb3b1a;
}

.hover\:bg-yellow-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 127 23 / var(--tw-bg-opacity));
}

.hover\:bg-opacity-20:hover {
  --tw-bg-opacity: .2;
}

.hover\:bg-opacity-40:hover {
  --tw-bg-opacity: .4;
}

.hover\:bg-opacity-80:hover {
  --tw-bg-opacity: .8;
}

.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:text-blue-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(96 125 139 / var(--tw-text-opacity));
}

.hover\:text-blue-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(38 50 56 / var(--tw-text-opacity));
}

.hover\:text-light-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgb(3 169 244 / var(--tw-text-opacity));
}

.hover\:text-primary-brand:hover {
  --tw-text-opacity: 1;
  color: rgb(62 174 91 / var(--tw-text-opacity));
}

.hover\:text-red-400:hover {
  --tw-text-opacity: 1;
  color: rgb(239 83 80 / var(--tw-text-opacity));
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

.hover\:opacity-75:hover {
  opacity: .75;
}

.hover\:opacity-90:hover {
  opacity: .9;
}

.hover\:shadow-cardShadow:hover {
  --tw-shadow: 0px 8px 20px #00000012, 0px 1px 2px #0000000f, 0px 1px 3px #0000001a;
  --tw-shadow-colored: 0px 8px 20px var(--tw-shadow-color), 0px 1px 2px var(--tw-shadow-color), 0px 1px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-amber-500\/40:hover {
  --tw-shadow-color: #ffc10766;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-blue-500\/40:hover {
  --tw-shadow-color: #2196f366;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-blue-gray-500\/20:hover {
  --tw-shadow-color: #607d8b33;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-blue-gray-500\/40:hover {
  --tw-shadow-color: #607d8b66;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-brown-500\/40:hover {
  --tw-shadow-color: #79554866;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-cyan-500\/40:hover {
  --tw-shadow-color: #00bcd466;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-deep-orange-500\/40:hover {
  --tw-shadow-color: #ff572266;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-deep-purple-500\/40:hover {
  --tw-shadow-color: #673ab766;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-gray-500\/40:hover {
  --tw-shadow-color: #9e9e9e66;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-green-500\/40:hover {
  --tw-shadow-color: #4caf5066;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-indigo-500\/40:hover {
  --tw-shadow-color: #3f51b566;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-light-blue-500\/40:hover {
  --tw-shadow-color: #03a9f466;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-light-green-500\/40:hover {
  --tw-shadow-color: #8bc34a66;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-lime-500\/40:hover {
  --tw-shadow-color: #cddc3966;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-orange-500\/40:hover {
  --tw-shadow-color: #ff980066;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-pink-500\/40:hover {
  --tw-shadow-color: #e91e6366;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-purple-500\/40:hover {
  --tw-shadow-color: #9c27b066;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-red-500\/40:hover {
  --tw-shadow-color: #f4433666;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-teal-500\/40:hover {
  --tw-shadow-color: #00968866;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:shadow-yellow-500\/40:hover {
  --tw-shadow-color: #ffeb3b66;
  --tw-shadow: var(--tw-shadow-colored);
}

.hover\:before\:opacity-10:hover:before {
  content: var(--tw-content);
  opacity: .1;
}

.hover\:before\:opacity-100:hover:before {
  content: var(--tw-content);
  opacity: 1;
}

.active\:bg-\[\#F3FAF5\]:active {
  --tw-bg-opacity: 1;
  background-color: rgb(243 250 245 / var(--tw-bg-opacity));
}

.active\:bg-amber-500\/30:active {
  background-color: #ffc1074d;
}

.active\:bg-blue-500\/30:active {
  background-color: #2196f34d;
}

.active\:bg-blue-gray-50:active {
  --tw-bg-opacity: 1;
  background-color: rgb(236 239 241 / var(--tw-bg-opacity));
}

.active\:bg-blue-gray-500\/30:active {
  background-color: #607d8b4d;
}

.active\:bg-brown-500\/30:active {
  background-color: #7955484d;
}

.active\:bg-cyan-500\/30:active {
  background-color: #00bcd44d;
}

.active\:bg-deep-orange-500\/30:active {
  background-color: #ff57224d;
}

.active\:bg-deep-purple-500\/30:active {
  background-color: #673ab74d;
}

.active\:bg-gray-500\/30:active {
  background-color: #9e9e9e4d;
}

.active\:bg-green-500\/30:active {
  background-color: #4caf504d;
}

.active\:bg-indigo-500\/30:active {
  background-color: #3f51b54d;
}

.active\:bg-light-blue-500\/30:active {
  background-color: #03a9f44d;
}

.active\:bg-light-green-500\/30:active {
  background-color: #8bc34a4d;
}

.active\:bg-lime-500\/30:active {
  background-color: #cddc394d;
}

.active\:bg-orange-500\/30:active {
  background-color: #ff98004d;
}

.active\:bg-pink-500\/30:active {
  background-color: #e91e634d;
}

.active\:bg-purple-500\/30:active {
  background-color: #9c27b04d;
}

.active\:bg-red-500\/30:active {
  background-color: #f443364d;
}

.active\:bg-teal-500\/30:active {
  background-color: #0096884d;
}

.active\:bg-transparent:active {
  background-color: #0000;
}

.active\:bg-white\/30:active {
  background-color: #ffffff4d;
}

.active\:bg-yellow-500\/30:active {
  background-color: #ffeb3b4d;
}

.active\:bg-opacity-80:active {
  --tw-bg-opacity: .8;
}

.active\:text-blue-gray-500:active {
  --tw-text-opacity: 1;
  color: rgb(96 125 139 / var(--tw-text-opacity));
}

.active\:text-blue-gray-900:active {
  --tw-text-opacity: 1;
  color: rgb(38 50 56 / var(--tw-text-opacity));
}

.active\:opacity-\[0\.85\]:active {
  opacity: .85;
}

.active\:shadow-\[inset_0px_2px_4px_rgba\(0\,_0\,_0\,_0\.16\)\]:active {
  --tw-shadow: inset 0px 2px 4px #00000029;
  --tw-shadow-colored: inset 0px 2px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.active\:shadow-none:active {
  --tw-shadow: 0 0 rgb(0, 0 / 0, 0);
  --tw-shadow-colored: 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

:is([dir="rtl"] .rtl\:text-right) {
  text-align: right;
}

:is(.dark .dark\:border-gray-600) {
  --tw-border-opacity: 1;
  border-color: rgb(117 117 117 / var(--tw-border-opacity));
}

:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 540px) {
  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:p-10 {
    padding: 2.5rem;
  }
}

@media (min-width: 720px) {
  .md\:inset-0 {
    inset: 0;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .md\:mb-\[40px\] {
    margin-bottom: 40px;
  }

  .md\:ml-6 {
    margin-left: 1.5rem;
  }

  .md\:mt-16 {
    margin-top: 4rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:min-h-\[100px\] {
    min-height: 100px;
  }

  .md\:min-h-\[354px\] {
    min-height: 354px;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:w-20 {
    width: 5rem;
  }

  .md\:w-5\/12 {
    width: 41.6667%;
  }

  .md\:w-6\/12 {
    width: 50%;
  }

  .md\:w-60 {
    width: 15rem;
  }

  .md\:w-7\/12 {
    width: 58.3333%;
  }

  .md\:w-80 {
    width: 20rem;
  }

  .md\:w-\[100\%\] {
    width: 100%;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:min-w-\[96px\] {
    min-width: 96px;
  }

  .md\:max-w-\[78px\] {
    max-width: 78px;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:overflow-auto {
    overflow: auto;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:p-\[10px\] {
    padding: 10px;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .md\:pt-7 {
    padding-top: 1.75rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-\[20px\] {
    font-size: 20px;
  }

  .md\:text-\[22px\] {
    font-size: 22px;
  }

  .md\:text-\[25px\] {
    font-size: 25px;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:\[box-shadow\:4px_4px_8px_rgba\(80\,_80\,_80\,_0\.16\)\] {
    box-shadow: 4px 4px 8px #50505029;
  }
}

@media (min-width: 960px) {
  .lg\:ml-\[200px\] {
    margin-left: 200px;
  }

  .lg\:ml-\[225px\] {
    margin-left: 225px;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-\[200px\] {
    width: 200px;
  }

  .lg\:min-w-\[200px\] {
    min-width: 200px;
  }

  .lg\:gap-10 {
    gap: 2.5rem;
  }

  .lg\:gap-x-\[60px\] {
    column-gap: 60px;
  }

  .lg\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .lg\:px-\[30px\] {
    padding-left: 30px;
    padding-right: 30px;
  }

  .lg\:px-\[42px\] {
    padding-left: 42px;
    padding-right: 42px;
  }

  .lg\:py-\[30px\] {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .lg\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .lg\:pr-\[200px\] {
    padding-right: 200px;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:pt-\[24px\] {
    padding-top: 24px;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1140px) {
  .xl\:order-1 {
    order: 1;
  }

  .xl\:order-2 {
    order: 2;
  }

  .xl\:order-3 {
    order: 3;
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:w-full {
    width: 100%;
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:gap-\[100px\] {
    gap: 100px;
  }
}

@media (min-width: 1320px) {
  .\32 xl\:max-w-full {
    max-width: 100%;
  }

  .\32 xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .desktop\:pr-\[30px\] {
    padding-right: 30px;
  }

  .desktop\:first\:pl-\[30px\]:first-child, .desktop\:last\:pl-\[30px\]:last-child {
    padding-left: 30px;
  }
}

@media (max-width: 1400px) {
  .smallDesktop\:ml-0 {
    margin-left: 0;
  }

  .smallDesktop\:px-\[30px\] {
    padding-left: 30px;
    padding-right: 30px;
  }

  .smallDesktop\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .smallDesktop\:pb-6 {
    padding-bottom: 1.5rem;
  }
}

@media (max-width: 1024px) {
  .miniDesktop\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .miniDesktop\:h-\[45px\] {
    height: 45px;
  }

  .miniDesktop\:flex-col {
    flex-direction: column;
  }

  .miniDesktop\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .miniDesktop\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .miniDesktop\:leading-normal {
    line-height: 1.5;
  }

  :is([dir="rtl"] .miniDesktop\:rtl\:text-left) {
    text-align: left;
  }
}

@media (max-width: 575.98px) {
  .maxMd\:m-\[30px\] {
    margin: 30px;
  }

  .maxMd\:mt-12 {
    margin-top: 3rem;
  }

  .maxMd\:mt-\[10px\] {
    margin-top: 10px;
  }

  .maxMd\:w-\[100\%\] {
    width: 100%;
  }

  .maxMd\:w-\[75\%\] {
    width: 75%;
  }

  .maxMd\:w-\[80\%\] {
    width: 80%;
  }

  .maxMd\:w-full {
    width: 100%;
  }

  .maxMd\:min-w-full {
    min-width: 100%;
  }

  .maxMd\:gap-\[14px\] {
    gap: 14px;
  }

  .maxMd\:p-3 {
    padding: .75rem;
  }

  .maxMd\:p-\[10px\] {
    padding: 10px;
  }

  .maxMd\:p-\[12px\] {
    padding: 12px;
  }

  .maxMd\:p-\[14px\] {
    padding: 14px;
  }

  .maxMd\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .maxMd\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .maxMd\:px-\[15px\] {
    padding-left: 15px;
    padding-right: 15px;
  }

  .maxMd\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .maxMd\:py-\[10px\] {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .maxMd\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .maxMd\:text-\[12px\] {
    font-size: 12px;
  }

  .maxMd\:text-\[16px\] {
    font-size: 16px;
  }

  .maxMd\:text-\[22px\] {
    font-size: 22px;
  }

  .maxMd\:text-\[25px\] {
    font-size: 25px;
  }
}

@media (max-width: 767.8px) {
  .maxlgMd\:p-\[16px\] {
    padding: 16px;
  }

  .maxlgMd\:text-\[15px\] {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .maxextMd\:max-w-\[65\%\] {
    max-width: 65%;
  }
}

@media (min-width: 991px) {
  .extMd\:w-\[100\%\] {
    width: 100%;
  }

  .extMd\:p-\[5px\] {
    padding: 5px;
  }

  .extMd\:text-\[20px\] {
    font-size: 20px;
  }

  .extMd\:text-\[30px\] {
    font-size: 30px;
  }

  .extMd\:text-\[36px\] {
    font-size: 36px;
  }
}

/*# sourceMappingURL=index.da8c9725.css.map */
